<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog slot="toolbar" v-model="modalVisible" persistent max-width="600px">
    <template v-slot:activator="{ on }">
      <v-btn color="primary" small outlined dark v-on="on">
        Düzenle
      </v-btn>
    </template>
    <v-card>
      <form-wrapper :validator="$v.form">
        <v-card-title>
          <span class="headline">Hoca Bilgilerini Düzenle</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <form-group cols="12" md="6" name="name">
                <v-text-field
                  slot-scope="{ attrs }"
                  v-bind="attrs"
                  label="İsim"
                  v-model="form.name"
                  @blur="$v.form.name.$touch()"
                ></v-text-field>
              </form-group>
              <form-group cols="12" md="6" name="surname">
                <v-text-field
                  slot-scope="{ attrs }"
                  v-bind="attrs"
                  label="Soyisim"
                  @blur="$v.form.surname.$touch()"
                  v-model="form.surname"
                ></v-text-field>
              </form-group>
              <form-group cols="12" md="6" name="title">
                <v-text-field
                  slot-scope="{ attrs }"
                  v-bind="attrs"
                  label="Görev"
                  @blur="$v.form.title.$touch()"
                  v-model="form.title"
                ></v-text-field>
              </form-group>
              <form-group cols="12" md="6" name="email">
                <v-text-field
                  slot-scope="{ attrs }"
                  v-bind="attrs"
                  label="Email"
                  v-model="form.email"
                  @blur="$v.form.email.$touch()"
                ></v-text-field>
              </form-group>
              <form-group cols="12" md="6" name="phone">
                <v-text-field
                  slot-scope="{ attrs }"
                  v-bind="attrs"
                  label="Cep Telefonu"
                  @blur="$v.form.phone.$touch()"
                  v-model="form.phone"
                ></v-text-field>
              </form-group>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="modalVisible = false">
            Kapat
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :disabled="$v.$anyError"
            @click="submit"
          >
            Kaydet
          </v-btn>
        </v-card-actions>
      </form-wrapper>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import _ from 'lodash';

export default {
  props: ['instructor'],

  mixins: [validationMixin],

  validations: {
    form: {
      name: { required },
      surname: { required },
      email: { required, email },
      phone: { required },
      title: { required }
    }
  },

  data() {
    return {
      loading: true,
      modalVisible: false,
      form: {}
    };
  },

  methods: {
    async submit() {
      try {
        await this.axios.patch(
          'admin/instructors/' + this.instructor.id,
          this.form
        );
        this.modalVisible = false;

        for (let key in this.form) {
          this.instructor[key] = this.form[key];
        }

        this.$store.dispatch('setToast', {
          text: 'Başarılı bir şekilde güncellendi!',
          color: 'success'
        });
      } catch (e) {
        this.$store.dispatch('setToast', {
          text: 'Güncelleme esnasında hata meydana geldi!',
          color: 'danger'
        });
        console.log(e);
      }
    }
  },
  watch: {
    modalVisible: {
      handler(nv) {
        if (nv) {
          this.form = _.cloneDeep(this.instructor);
        }
      }
    }
  }
};
</script>
