<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-col>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Cami listesini getirir
      </div>
    </b-alert>
    <v-row>
      <v-col md="4">
        <card title="Eğitmen Bilgileri">
          <InstructorUpdate
            slot="toolbar"
            :instructor="instructor"
          ></InstructorUpdate>
          <detail-list-item field="Adı" :value="instructor.name" />
          <detail-list-item field="Soyadı" :value="instructor.surname" />
          <detail-list-item field="Unvan" :value="instructor.title" />
          <detail-list-item field="TC Kimlik Numarası" :value="instructor.tc" />
          <detail-list-item field="Cep Telefonu" :value="instructor.phone" />
          <detail-list-item field="Eposta" :value="instructor.email" />
        </card>
      </v-col>
      <v-col md="8">
        <CourseList :instructor="instructor"></CourseList>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { SET_BREADCRUMB } from '@/store/breadcrumbs.module';
import DetailListItem from '@/view/content/components/DetailListItem.vue';
import InstructorUpdate from './InstructorUpdate';
import CourseList from '@/view/content/components/Course/CourseList';
import moment from 'moment';
import 'moment/locale/tr';

export default {
  props: ['instructorId', 'schoolId'],
  components: { DetailListItem, InstructorUpdate, CourseList },

  data() {
    return {
      instructor: {
        id: this.instructorId,
        schoolId: this.schoolId
      }
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Kurumlar' },
      { title: 'Eğitmenler' },
      { title: 'Eğitmen Detay' }
    ]);
    this.getInstructor();
  },
  methods: {
    async getInstructor() {
      try {
        const { data } = await this.axios.get(
          'admin/instructors/' + this.instructorId
        );

        this.instructor = { ...data, schoolId: this.schoolId };
      } catch (e) {
        console.log(e);
      }
    },
    moment
  }
};
</script>
